import { getDisplayName, Spinner } from '@cp-shared-5/frontend-ui';
import React, { ReactElement } from 'react';

export type withLoadingHandlerProps = {
    isLoading: boolean;
    loadingPlaceholder?: ReactElement;
};

export const withLoadingHandler = <TProps extends object>(
    WrappedComponent: React.ComponentType<TProps>,
): React.FC<TProps & withLoadingHandlerProps> => {
    const Wrapper: React.FC<TProps & withLoadingHandlerProps> = ({
        isLoading,
        loadingPlaceholder,
        ...props
    }: withLoadingHandlerProps) =>
        isLoading ? loadingPlaceholder || <Spinner center={true} /> : <WrappedComponent {...(props as TProps)} />;

    Wrapper.displayName = `withLoadingHandler(${getDisplayName(WrappedComponent)})`;

    return Wrapper;
};
