import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const identificationFormValidationSchema = (t: TFunction) => {
    return Yup.object().shape({
        smsToken: Yup.string()
            .trim()
            .required(t('sms-form.validation-errors.sms-code-missing'))
            .matches(/^\d+$/, t('sms-form.validation-errors.sms-code-invalid-format'))
            .length(6, t('sms-form.validation-errors.sms-code-invalid-length')),
    });
};
