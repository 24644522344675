import { useState } from 'react';
import { ActionType, useAnalyticsActionTracker } from '@cp-shared-5/frontend-ui';

export const useTrackerOnBeforeUnload = (trackingId: ActionType = 'onConfirmIdentityAborted') => {
    const [lastTouchedField, setLastTouchedField] = useState<string>();

    const { onAction } = useAnalyticsActionTracker(trackingId);

    window.onbeforeunload = () => {
        onAction(lastTouchedField);
    };
    return setLastTouchedField;
};
