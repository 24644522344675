import { IconFooterLoadingPlaceholder } from '@cp-shared-5/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { IconFooterUi } from './ui';
import { useIconFooter } from './useIconFooter';

const IconFooterWithHandlers = withLoadingAndNoConnectionHandler(IconFooterUi);

export const IconFooter: React.FC = () => {
    const { cmsContent: iconFooter, isLoading, loadingError } = useIconFooter();

    return (
        <IconFooterWithHandlers
            isLoading={isLoading}
            iconFooter={iconFooter}
            hasError={!!loadingError}
            loadingPlaceholder={<IconFooterLoadingPlaceholder />}
        />
    );
};
